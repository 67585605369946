<template>
  <div class="row pr cinzento m-0">
    <div class="text-center balizas-calendario cinzento">
      <div class="p-10"><button class="btn btn-success btn-default" @click="onDayClick">Hoje</button></div>
      <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>
    </div>

    <div class="balizas-tabela cinzento1" v-if="$store.state.user.email === 'fserra18@gmail.com' || $store.state.user.email === 'vania_ss@hotmail.com'">
      <h3 class="ml-20">Relatório</h3>
      <div class="row p-10">
        <div class="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div class="radio-custom radio-primary mr-20 float-left">
            <input @change="estatisticasFN()" class="foco" type="radio" name="pesaco" id="all123" v-model="espaco" value="todos" checked>
            <label for="all123">Todos</label>
          </div>
          <div class="radio-custom radio-primary mr-20 float-left">
            <input @change="estatisticasFN()" class="foco" type="radio" name="pesaco" id="balizaslandia123" v-model="espaco" value="smf">
            <label for="balizaslandia123">Balizaslandia SMF</label>
          </div>
          <div class="radio-custom radio-primary mr-20 float-left">
            <input @change="estatisticasFN()" class="foco" type="radio" name="pesaco" id="balizaslandia_oaz" v-model="espaco" value="oaz">
            <label for="balizaslandia_oaz">Balizaslandia OAZ</label>
          </div>
        </div>
        <div class="form-group col-xs-6 col-sm-5 col-md-5 col-lg-4">
          <div class="input-group">
            <span class="input-group-addon">{{l('data_inicio')}}</span>
            <input :min="dataMin" :max="ano + '-12-31'" type="date" class="form-control foco" v-model="relatorios1.before" @keydown.enter.prevent="FOCO" id="data_inicio1232332">
          </div>
        </div>
        <div class="form-group col-xs-6 col-sm-5 col-md-5 col-lg-4">
          <div class="input-group">
            <span class="input-group-addon">{{l('data_fim')}}</span>
            <input :min="dataMin" :max="ano + '-12-31'" type="date" class="form-control foco" v-model="relatorios1.after" @keydown.enter.prevent="FOCO" id="data_inicio2232332" />
          </div>
        </div>
        <div class="form-group col-xs-12 col-sm2 col-md-2 col-lg-4">
          <button @click="estatisticasFN()" class="btn btn-primary btn-block">Pesquisar</button>
        </div>
      </div>
      <div class="cinzento1" v-if="contas">
        <table id="tabelaFixa" class="table balizas" style="width:auto;margin: 0 auto;">
          <thead>
            <tr>
              <th style="top:0px;" :class="{'meio text-center': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">Descrição</th>
              <th style="top:0px;width:100px;" :class="{'meio text-center': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">Quant.</th>
              <th style="top:0px;width:150px;" :class="{'meio text-center': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">Total Crianças</th>
              <th style="top:0px;width:150px;" :class="{'meio text-center': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">Valor Numerário</th>
              <th style="top:0px;width:150px;" :class="{'meio text-center': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">Valor Transf/Mbway</th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <tr>
              <th colspan="100%" class="cinzento1">Festas de Aniversário (MENU + EXTRAS)</th>
            </tr>
            <tr>
              <td class="meio slot">Menu Normal</td>
              <td class="meio text-right">{{contas.menu1[0]}}</td>
              <td class="meio text-right">{{contas.menu1[1]}}</td>
              <td class="meio text-right">{{contas.menu1[2].toFixed(2)}} €</td>
              <td class="meio text-right">{{contas.menu1[3].toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Menu Premium</td>
              <td class="meio text-right">{{contas.menu2[0]}}</td>
              <td class="meio text-right">{{contas.menu2[1]}}</td>
              <td class="meio text-right">{{contas.menu2[2].toFixed(2)}} €</td>
              <td class="meio text-right">{{contas.menu2[3].toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Menu Normal Almoço/Jantar</td>
              <td class="meio text-right">{{contas.menu3[0]}}</td>
              <td class="meio text-right">{{contas.menu3[1]}}</td>
              <td class="meio text-right">{{contas.menu3[2].toFixed(2)}} €</td>
              <td class="meio text-right">{{contas.menu3[3].toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Menu Premium Almoço/Jantar</td>
              <td class="meio text-right">{{contas.menu4[0]}}</td>
              <td class="meio text-right">{{contas.menu4[1]}}</td>
              <td class="meio text-right">{{contas.menu4[2].toFixed(2)}} €</td>
              <td class="meio text-right">{{contas.menu4[3].toFixed(2)}} €</td>
            </tr>
            <tr :class="{'texto-branco': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">
              <th class="meio text-right">Total Festas</th>
              <th class="meio text-right">{{contas.menu1[0] + contas.menu2[0] + contas.menu3[0] + contas.menu4[0]}}</th>
              <th class="meio text-right">{{contas.menu1[1] + contas.menu2[1] + contas.menu3[1] + contas.menu4[1]}}</th>
              <th class="meio text-right">{{(contas.menu1[2] + contas.menu2[2] + contas.menu3[2] + contas.menu4[2]).toFixed(2)}} €</th>
              <th class="meio text-right">{{(contas.menu1[3] + contas.menu2[3] + contas.menu3[3] + contas.menu4[3]).toFixed(2)}} €</th>
            </tr>
            <tr>
              <td colspan="100%"></td>
            </tr>
            <tr>
              <th colspan="100%" class="cinzento1">Entradas Livres</th>
            </tr>
            <tr>
              <td class="meio slot">Entrada 1H</td>
              <td style="width:80px;" class="text-right">{{contas.tnum1 + contas.tbanco1}}</td>
              <td style="width:80px;" class="text-right">{{contas.tnum1 + contas.tbanco1}}</td>
              <td style="width:80px;" class="text-right">{{(contas.tnum1 * (year >= 2024 ? 6 : 5)).toFixed(2)}} €</td>
              <td style="width:80px;" class="text-right">{{(contas.tbanco1 * (year >= 2024 ? 6 : 5)).toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Entrada 2H</td>
              <td class="text-right">{{contas.tnum2 + contas.tbanco2}}</td>
              <td class="text-right">{{contas.tnum2 + contas.tbanco2}}</td>
              <td class="text-right">{{(contas.tnum2 * (year >= 2024 ? 10 : 8)).toFixed(2)}} €</td>
              <td class="text-right">{{(contas.tbanco2 * (year >= 2024 ? 10 : 8)).toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Entrada 3H</td>
              <td class="text-right">{{contas.tnum3 + contas.tbanco2}}</td>
              <td class="text-right">{{contas.tnum3 + contas.tbanco2}}</td>
              <td class="text-right">{{(contas.tnum3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2)}} €</td>
              <td class="text-right">{{(contas.tbanco3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Vales (1H/2H)</td>
              <td class="text-right">{{contas.vales}}</td>
              <td class="text-right">{{contas.vales}}</td>
              <td class="text-center">--</td>
              <td class="text-center">--</td>
            </tr>
            <!--<tr>
              <th>Total Crianças</th>
              <td></td>
              <th class="text-center">{{contas.tnum1 + contas.tnum2 + contas.vales + contas.tbanco1 + contas.tbanco2}}</th>
              <th class="text-center">{{contas.tnum1 * (year >= 2024 ? 6 : 5) + contas.tnum2 * (year >= 2024 ? 10 : 8)}} €</th>
              <th class="text-center">{{contas.tbanco1 * (year >= 2024 ? 6 : 5) + contas.tbanco2 * (year >= 2024 ? 10 : 8)}} €</th>
            </tr>-->
            <tr>
              <td class="meio slot">Lanches</td>
              <td class="text-right">{{contas.lanche1 + contas.lanche3}}</td>
              <td class="text-center">--</td>
              <td class="text-right">{{(contas.lanche1 * 2.5).toFixed(2)}} €</td>
              <td class="text-right">{{(contas.lanche3 * 2.5).toFixed(2)}} €</td>
            </tr>

            <tr>
              <td class="meio slot">Adultos</td>
              <td class="text-right">{{contas.adulto1 + contas.adulto3}}</td>
              <td class="text-center">--</td>
              <td class="text-right">{{(contas.adulto1 * 2).toFixed(2)}} €</td>
              <td class="text-right">{{(contas.adulto3 * 2).toFixed(2)}} €</td>
            </tr>
            <!--<tr>
              <td class="meio slot">Horas Extras</td>
              <td class="text-right">{{contas.tnum3 + contas.tbanco3}}</td>
              <td class="text-center">--</td>
              <td class="text-right">{{(contas.tnum3 * 3.5).toFixed(2)}} €</td>
              <td class="text-right">{{(contas.tbanco3 * 3.5).toFixed(2)}} €</td>
            </tr>-->
            <tr :class="{'texto-branco': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">
              <th class="meio text-right">Total Entradas Livres</th>
              <th class="meio text-right"><!--{{contas.tnum1 + contas.tbanco1 + contas.tnum2 + contas.tbanco2 + contas.vales + contas.tnum3 + contas.tbanco3 + contas.lanche1 + contas.lanche3}}-->--</th>
              <th class="meio text-right">{{contas.tnum1 + contas.tbanco1 + contas.tnum2 + contas.tbanco2 + contas.tnum3 + contas.tbanco3 + contas.vales}}</th>
              <th class="meio text-right">{{(contas.tnum1 * (year >= 2024 ? 6 : 5) + contas.tnum2 * (year >= 2024 ? 10 : 8) + contas.lanche1 * 2.5 + contas.adulto1 * 2 + contas.tnum3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2)}} €</th>
              <th class="meio text-right">{{(contas.tbanco1 * (year >= 2024 ? 6 : 5) + contas.tbanco2 * (year >= 2024 ? 10 : 8) + contas.lanche3 * 2.5 + contas.adulto3 * 2 + contas.tbanco3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2)}} €</th>
            </tr>

            <tr>
              <td colspan="100%"></td>
            </tr>
            <tr>
              <th colspan="100%" class="cinzento1">Escolas / Centro de Estudos</th>
            </tr>
            <tr :class="{'texto-branco': 1, 'azul': espaco === 'smf', 'preto': espaco === 'todos', 'amarelo': espaco === 'oaz'}">
              <th class="meio text-right">Total Escolas / Centro de Estudos</th>
              <th class="meio text-right">{{contas.centro[0]}}</th>
              <th class="meio text-right">{{contas.centro[1]}}</th>
              <th class="meio text-right">{{contas.centro[2].toFixed(2)}} €</th>
              <th class="meio text-right">{{contas.centro[3].toFixed(2)}} €</th>
            </tr>

            <tr>
              <td colspan="100%"></td>
            </tr>

            <tr class="cinzento texto-branco">
              <td class="text-right" colspan="2">Total</td>
              <th class="meio text-right">{{contas.centro[1] + contas.tnum1 + contas.tbanco1 + contas.tnum2 + contas.tbanco2 + contas.vales + contas.menu1[1] + contas.menu2[1] + contas.menu3[1] + contas.menu4[1]}}</th>
              <th class="meio text-right" >{{(contas.menu1[2] + contas.menu2[2] + contas.menu3[2] + contas.menu4[2] + contas.centro[2] + contas.tnum1 * (year >= 2024 ? 6 : 5) + contas.tnum2 * (year >= 2024 ? 10 : 8) + contas.lanche1 * 2.5 + contas.adulto1 * 2 + contas.tnum3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2) }} €</th>
              <th class="meio text-right">{{(contas.menu1[3] + contas.menu2[3] + contas.menu3[3] + contas.menu4[3] + contas.centro[3] + contas.tbanco1 * (year >= 2024 ? 6 : 5) + contas.tbanco2 * (year >= 2024 ? 10 : 8) + contas.lanche3 * 2.5 + contas.adulto3 * 2 + contas.tbanco3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2)}} €</th>
            </tr>

            <tr>
              <td colspan="100%"></td>
            </tr>

            <tr>
              <th colspan="100%" class="cinzento1">Outros</th>
            </tr>
            <tr>
              <td class="meio slot">Cauções 40€</td>
              <td style="width:80px;" class="text-right">{{contas.caucoes40[0] + contas.caucoes40[1]}}</td>
              <td style="width:80px;" class="text-center">--</td>
              <td style="width:80px;" class="text-right">{{(contas.caucoes40[0] * 40).toFixed(2)}} €</td>
              <td style="width:80px;" class="text-right">{{(contas.caucoes40[1] * 40).toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Cauções outros valores</td>
              <td style="width:80px;" class="text-right">{{contas.caucoes[2]}}</td>
              <td style="width:80px;" class="text-center">--</td>
              <td style="width:80px;" class="text-right">{{contas.caucoes[0].toFixed(2)}} €</td>
              <td style="width:80px;" class="text-right">{{contas.caucoes[1].toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Meias</td>
              <td style="width:80px;" class="text-right">{{contas.meias[0] + contas.meias[1]}}</td>
              <td style="width:80px;" class="text-center">--</td>
              <td style="width:80px;" class="text-right">{{(contas.meias[0] * 2).toFixed(2)}} €</td>
              <td style="width:80px;" class="text-right">{{(contas.meias[1] * 2).toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Outros valores recebidos</td>
              <td style="width:80px;" class="text-right">--<!--{{contas.outros[2]}}--></td>
              <td style="width:80px;" class="text-center">--</td>
              <td style="width:80px;" class="text-right">{{contas.outros[0].toFixed(2)}} €</td>
              <td style="width:80px;" class="text-right">{{contas.outros[1].toFixed(2)}} €</td>
            </tr>
            <tr>
              <td class="meio slot">Outros valores pagos</td>
              <td style="width:80px;" class="text-right">--<!--{{contas.outros1[3]}}--></td>
              <td style="width:80px;" class="text-center">--</td>
              <td style="width:80px;" class="text-right">{{contas.outros1[0].toFixed(2)}} €</td>
              <td style="width:80px;" class="text-right">{{contas.outros1[1].toFixed(2)}} €</td>
            </tr>
            <tr class="cinzento texto-branco">
              <td class="text-right" colspan="3">Total</td>
              <th class="meio text-right">{{(contas.outros[0] - contas.outros1[0].toFixed(2) + contas.meias[0] * 2 + contas.caucoes[0] + contas.caucoes40[0] * 40).toFixed(2)}} €</th>
              <th class="meio text-right">{{(contas.outros[1] - contas.outros1[1].toFixed(2) + contas.meias[1] * 2 + contas.caucoes[1] + contas.caucoes40[1] * 40).toFixed(2)}} €</th>
            </tr>
            <tr>
              <td colspan="100%"></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th class="meio text-right" colspan="2">Total</th>
              <th class="meio text-right">{{contas.centro[1] + contas.tnum1 + contas.tbanco1 + contas.tnum2 + contas.tbanco2 + contas.vales + contas.menu1[1] + contas.menu2[1] + contas.menu3[1] + contas.menu4[1]}}</th>
              <th class="meio text-right">{{(contas.outros[0] - contas.outros1[0] + contas.meias[0] * 2 + contas.caucoes[0] + contas.caucoes40[0] * 40 + contas.menu1[2] + contas.menu2[2] + contas.menu3[2] + contas.menu4[2] + contas.centro[2] + contas.tnum1 * (year >= 2024 ? 6 : 5) + contas.tnum2 * (year >= 2024 ? 10 : 8) + contas.lanche1 * 2.5 + contas.adulto1 * 2 + contas.tnum3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2)}} €</th>
              <th class="meio text-right">{{(contas.outros[1] - contas.outros1[1] + contas.meias[1] * 2 + contas.caucoes[1] + contas.caucoes40[1] * 40 + contas.menu1[3] + contas.menu2[3] + contas.menu3[3] + contas.menu4[3] + contas.centro[3] + contas.tbanco1 * (year >= 2024 ? 6 : 5) + contas.tbanco2 * (year >= 2024 ? 10 : 8) + contas.lanche3 * 2.5 + contas.adulto3 * 2 + contas.tbanco3 * (year >= 2024 ? 14.5 : 11.5)).toFixed(2)}} €</th>
            </tr>
            <tr>
              <td colspan="3"></td>
              <th class="meio text-right">{{(contas.outros[0] - contas.outros1[0] + contas.meias[0] * 2 + contas.caucoes[0] + contas.caucoes40[0] * 40 + contas.menu1[2] + contas.menu2[2] + contas.menu3[2] + contas.menu4[2] + contas.centro[2] + contas.tnum1 * (year >= 2024 ? 6 : 5) + contas.tnum2 * (year >= 2024 ? 10 : 8) + contas.lanche1 * 2.5 + contas.adulto1 * 2 + contas.tnum3 * (year >= 2024 ? 14.5 : 11.5) + 70).toFixed(2)}} €</th>
              <td></td>
            </tr>
          </tfoot>
        </table>
        <h3 class="text-center">Total Festas + Ent. Livres: {{ (contas.menu1[2] + contas.menu2[2] + contas.menu3[2] + contas.menu4[2] + contas.menu1[3] + contas.menu2[3] + contas.menu3[3] + contas.menu4[3] + contas.tnum1 * (year >= 2024 ? 6 : 5) + contas.tnum2 * (year >= 2024 ? 10 : 8) + contas.lanche1 * 2.5 + contas.adulto1 * 2 + contas.tnum3 * (year >= 2024 ? 14.5 : 11.5) + contas.tbanco1 * (year >= 2024 ? 6 : 5) + contas.tbanco2 * (year >= 2024 ? 10 : 8) + contas.lanche3 * 2.5 + contas.adulto3 * 2 + contas.tbanco3 * (year >= 2024 ? 14.5 : 11.5) + contas.centro[2] + contas.centro[3] + contas.caucoesX).toFixed(2)}} €</h3>
        <h3 class="text-center">PP: {{ (contas.pp + contas.pp1).toFixed(2) }} €</h3>
      </div>
    </div>
  </div>
</template>

<script>
import { searchEvent } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { commonFunctionsMixin } from '../filters/funcoes'

export default {
  name: 'pageRelatorios',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      res: [],

      contas: '',
      caixa: 0,
      caixa1: {
        o: '',
        o1: '',
        o2: '',
        c40: '',
        c401: '',
        c: '',
        c1: '',
        m: '',
        m1: '',
        op1: '',
        op: '',
        op2: '',
        fecho: 0,
        fecho1: 0,
        nome: '',
        id: '',
        pp: 0
      },
      caixas: [],
      relatorios1: {
        before: new Date(),
        after: new Date()
      },
      year: 2024,
      day: '',
      month: '',
      ano: new Date().getFullYear() + 1,
      dataMin: '2017-01-01',
      espaco: 'todos',

      attributes: [{
        dates: new Date(),
        key: 'today',
        highlight: true
      }]
    }
  },
  mounted () {
    this.SET_TITLE('')
    this.SET_LOAD(true)
    /*
    const dd = new Date()
    this.relatorios1 = {
      before: dd.getFullYear() + '-' + ('0' + (1 + dd.getMonth())).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2),
      after: dd.getFullYear() + '-' + ('0' + (1 + dd.getMonth())).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2)
    }
    */
    // this.estatisticasFN()

    let dd = new Date()
    this.month = ('0' + (1 + dd.getMonth())).slice(-2)
    this.year = dd.getFullYear()
    this.day = ('0' + dd.getDate()).slice(-2)

    this.relatorios1 = {
      before: dd.getFullYear() + '-' + ('0' + (1 + dd.getMonth())).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2),
      after: dd.getFullYear() + '-' + ('0' + (1 + dd.getMonth())).slice(-2) + '-' + ('0' + dd.getDate()).slice(-2)
    }
    this.estatisticasFN()
    this.feriadosFN(this.year)
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'SET_BALIZAS'
    ]),
    l (a) {
      return this.$t('home.' + a)
    },

    onDayClick (dia) {
      if (!dia.id) {
        let a = new Date()
        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)
        dia = { id: this.year + '-' + this.month + '-' + this.day }
      }
      this.attributes[0].dates = new Date(dia.id.replace(/-/g, '/'))
      this.$refs.calendar.move(dia.id)
      this.month = dia.id.split('-')[1]
      this.year = dia.id.split('-')[0]
      this.day = dia.id.split('-')[2]
      this.feriadosFN(this.year)
      this.relatorios1.before = this.year + '-' + this.month + '-' + this.day
      this.relatorios1.after = this.year + '-' + this.month + '-' + this.day
      this.estatisticasFN(1)
    },

    estatisticasFN () {
      this.SET_LOAD(true)
      let a = new Date(this.relatorios1.before)
      let b = new Date(this.relatorios1.after)
      let cal = []
      switch (this.espaco) {
      case 'smf':
        cal = [6, 69, 67, 70]
        break
      case 'oaz':
        cal = [107, 111, 110, 112]
        break
      default:
        cal = [107, 111, 110, 112, 6, 69, 67, 70]
        break
      }
      searchEvent(this.axios, cal, '', '', '', b.getFullYear() + '-' + ('0' + (1 + b.getMonth())).slice(-2) + '-' + ('0' + b.getDate()).slice(-2), a.getFullYear() + '-' + ('0' + (1 + a.getMonth())).slice(-2) + '-' + ('0' + a.getDate()).slice(-2), '', '').then((res) => {
        this.caixa1 = {}
        if (this.espaco === 'todos') {
          this.livres = res.data.filter(a => parseInt(a.calendar_id) === cal[2] || parseInt(a.calendar_id) === cal[6])
          this.caixas = res.data.filter(a => parseInt(a.calendar_id) === cal[3] || parseInt(a.calendar_id) === cal[7])
        } else {
          this.livres = res.data.filter(a => parseInt(a.calendar_id) === cal[2])
          this.caixas = res.data.filter(a => parseInt(a.calendar_id) === cal[3])
        }

        let b = new Date()
        b.setHours(7)
        this.res = res.data.filter(a => (parseInt(a.calendar_id) === cal[0] || parseInt(a.calendar_id) === cal[4]) && (new Date(a.event_at.replace(/-/g, '/')) > b || this.meta(a, 'PP') || this.$store.state.user.email === 'fserra18@gmail.com' || this.$store.state.user.email === 'vania_ss@hotmail.com' || this.$store.state.user.email === 'landiastaff@balizaslandia.pt')).concat(this.slots).sort((a, b) => new Date(a.event_at.replace(/-/g, '/')) - new Date(b.event_at.replace(/-/g, '/')))
        this.total1 = 0
        this.total2 = 0

        this.contas = {
          tnum1: 0,
          tnum2: 0,
          tnum3: 0,
          tbanco1: 0,
          tbanco2: 0,
          tbanco3: 0,
          lanche1: 0,
          lanche3: 0,
          adulto1: 0,
          adulto3: 0,
          vales: 0,

          menu1: [0, 0, 0, 0],
          menu2: [0, 0, 0, 0],
          menu3: [0, 0, 0, 0],
          menu4: [0, 0, 0, 0],
          centro: [0, 0, 0, 0],
          caucoes40: [0, 0],
          caucoes: [0, 0, 0],
          meias: [0, 0],
          outros: [0, 0, 0, ''],
          outros1: [0, 0, '', 0],
          pp: 0,
          pp1: 0,
          caucoesX: 0,
          meiasPP: 0
        }
        let i = this.livres.length
        while (--i >= 0) {
          let crianca = this.meta(this.livres[i], 'Crianças').split(',').length
          this.contas.tnum1 += (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.livres[i], 'tempo')) === 1 ? crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0) : 0)
          this.contas.tnum2 += ((this.meta(this.livres[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.livres[i], 'tempo')) === 2) ? crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0) : 0)
          this.contas.tnum3 += (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.livres[i], 'tempo')) === 3 ? crianca : 0)

          this.contas.tbanco1 += (((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.livres[i], 'tempo')) === 1) ? crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0) : 0)
          this.contas.tbanco2 += (((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.livres[i], 'tempo')) === 2) ? crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0) : 0)
          this.contas.tbanco3 += (((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.livres[i], 'tempo')) === 3) ? crianca : 0)

          this.contas.lanche1 += (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && this.meta(this.livres[i], 'Lanche1') ? crianca : 0)
          this.contas.lanche3 += ((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && this.meta(this.livres[i], 'Lanche1') ? crianca : 0)

          this.contas.adulto1 += (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && this.meta(this.livres[i], 'Adulto') ? parseInt(this.meta(this.livres[i], 'tempo')) * this.meta(this.livres[i], 'Adulto') : 0)
          this.contas.adulto3 += ((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && this.meta(this.livres[i], 'Adulto') ? parseInt(this.meta(this.livres[i], 'tempo')) * this.meta(this.livres[i], 'Adulto') : 0)

          this.contas.vales += (parseInt(this.meta(this.livres[i], 'Vales')) || 0)

          if (this.meta(this.livres[i], 'Pagamento') === 'Numerário') {
            this.contas.meias[0] += parseInt(this.meta(this.livres[i], 'Meias') || 0)
          }
          if (this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') {
            this.contas.meias[1] += parseInt(this.meta(this.livres[i], 'Meias') || 0)
          }

          if (this.meta(this.livres[i], 'PP')) {
            this.contas.pp += (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.livres[i], 'tempo')) === 1 ? crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0) : 0) * (this.year >= 2024 ? 6 : 5) + ((this.meta(this.livres[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.livres[i], 'tempo')) === 2) ? crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0) : 0) * (this.year >= 2024 ? 10 : 8) + (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && parseInt(this.meta(this.livres[i], 'tempo')) === 3 ? crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0) : 0) * (this.year >= 2024 ? 14.5 : 11.5) + (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && this.meta(this.livres[i], 'Lanche1') ? crianca * 2.5 : 0) + (this.meta(this.livres[i], 'Pagamento') === 'Numerário' && this.meta(this.livres[i], 'Adulto') ? parseInt(this.meta(this.livres[i], 'tempo')) * 2 * this.meta(this.livres[i], 'Adulto') : 0)

            this.contas.pp1 += (((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.livres[i], 'tempo')) === 1) ? (crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0)) * (this.year >= 2024 ? 6 : 5) : 0) + (((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.livres[i], 'tempo')) === 2) ? (crianca - parseInt(this.meta(this.livres[i], 'Vales') || 0)) * (this.year >= 2024 ? 10 : 8) : 0) + (((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && parseInt(this.meta(this.livres[i], 'tempo')) === 3) ? crianca * (this.year >= 2024 ? 14.5 : 11.5) : 0) + ((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && this.meta(this.livres[i], 'Lanche1') ? crianca * 2.5 : 0) + ((this.meta(this.livres[i], 'Pagamento') === 'Mbway' || this.meta(this.livres[i], 'Pagamento') === 'Transferência') && this.meta(this.livres[i], 'Adulto') ? parseInt(this.meta(this.livres[i], 'tempo')) * 2 * this.meta(this.livres[i], 'Adulto') : 0)
            if (this.meta(this.livres[i], 'Pagamento') === 'Numerário') {
              this.contas.meiasPP += parseInt(this.meta(this.livres[i], 'Meias') || 0)
            }
          }
        }
        i = this.res.length
        while (--i >= 0) {
          if (!this.meta(this.res[i], 'clonado') && !this.meta(this.res[i], 'Bloqueado') && !this.meta(this.res[i], 'BloqueadoDia')) {
            switch (this.meta(this.res[i], 'menu')) {
            case 'Normal 11€':
            case 'Normal 12€':
            case 'Normal 13€':
            case 'Normal 14€':
            case 'Normal 15€':
              ++this.contas.menu1[0]
              this.contas.menu1[1] += (parseInt(this.meta(this.res[i], 'Total Crianças')) || 0)
              this.contas.menu1[2] += (parseFloat(this.meta(this.res[i], 'Total1')) || 0)
              this.contas.menu1[3] += (parseFloat(this.meta(this.res[i], 'Total2')) || 0)
              break
            case 'Premium 18€':
            case 'Premium 19€':
            case 'Premium 20€':
              ++this.contas.menu2[0]
              this.contas.menu2[1] += (parseInt(this.meta(this.res[i], 'Total Crianças')) || 0)
              this.contas.menu2[2] += (parseFloat(this.meta(this.res[i], 'Total1')) || 0)
              this.contas.menu2[3] += (parseFloat(this.meta(this.res[i], 'Total2')) || 0)
              break
            case 'Normal Almoço/Jantar 14€':
            case 'Normal Almoço/Jantar 15€':
            case 'Normal Almoço/Jantar 16€':
            case 'Normal Almoço/Jantar 17€':
            case 'Normal Almoço/Jantar 18€':
              ++this.contas.menu3[0]
              this.contas.menu3[1] += (parseInt(this.meta(this.res[i], 'Total Crianças')) || 0)
              this.contas.menu3[2] += (parseFloat(this.meta(this.res[i], 'Total1')) || 0)
              this.contas.menu3[3] += (parseFloat(this.meta(this.res[i], 'Total2')) || 0)
              break
            case 'Premium Almoço/Jantar 22€':
            case 'Premium Almoço/Jantar 23€':
              ++this.contas.menu4[0]
              this.contas.menu4[1] += (parseInt(this.meta(this.res[i], 'Total Crianças')) || 0)
              this.contas.menu4[2] += (parseFloat(this.meta(this.res[i], 'Total1')) || 0)
              this.contas.menu4[3] += (parseFloat(this.meta(this.res[i], 'Total2')) || 0)
              break
            case 'Centro Estudos 5€':
            case 'Centro Estudos 6€':
            case 'Centro Estudos':
              ++this.contas.centro[0]
              this.contas.centro[1] += (parseInt(this.meta(this.res[i], 'Total Crianças')) || 0)
              this.contas.centro[2] += (parseFloat(this.meta(this.res[i], 'Total1')) || 0)
              this.contas.centro[3] += (parseFloat(this.meta(this.res[i], 'Total2')) || 0)
              break
            }
            if (parseInt(this.meta(this.res[i], 'Caução Paga')) === 1) {
              this.contas.caucoesX += parseFloat(this.meta(this.res[i], 'caucao_paga')) || 0
            }
            if (this.meta(this.res[i], 'PP')) {
              this.contas.pp += (parseFloat(this.meta(this.res[i], 'Total1')) || 0)
              this.contas.pp1 += (parseFloat(this.meta(this.res[i], 'Total2')) || 0)
            }

            if (parseFloat(this.meta(this.res[i], 'Total1')) > 0) {
              this.contas.meias[0] += parseInt(this.meta(this.res[i], 'Meias')) || 0
              if (this.meta(this.res[i], 'PP')) {
                this.contas.meiasPP += parseInt(this.meta(this.res[i], 'Meias'))
              }
            } else {
               if (parseFloat(this.meta(this.res[i], 'Total2')) > 0) {
                this.contas.meias[1] += parseInt(this.meta(this.res[i], 'Meias')) || 0
               }
            }
          }
        }
        i = this.caixas.length
        while (--i >= 0) {
          this.contas.caucoes40[0] += parseInt(this.meta(this.caixas[i], 'Total1')) || 0
          this.contas.caucoes[0] += parseInt(this.meta(this.caixas[i], 'Vales')) || 0
          this.contas.meias[0] += parseInt(this.meta(this.caixas[i], 'Anos')) || 0

          if (this.meta(this.caixas[i], 'PP')) {
            this.contas.meiasPP += parseInt(this.meta(this.caixas[i], 'Anos')) || 0
          }
          this.contas.outros[0] += parseFloat(this.meta(this.caixas[i], 'Anos 3')) || 0
          this.contas.outros1[0] += parseFloat(this.meta(this.caixas[i], 'bolo_tema')) || 0

          if (this.contas.outros[0] > 0 || this.contas.outros[1] > 0) {
            ++this.contas.outros[2]
          }
          if (this.contas.caucoes[0] > 0 || this.contas.caucoes[1] > 0) {
            ++this.contas.caucoes[2]
          }

          if (this.contas.outros1[0] > 0 || this.contas.outros1[1] > 0) {
            ++this.contas.outros1[3]
          }

          this.contas.caucoes40[1] += parseInt(this.meta(this.caixas[i], 'Total2')) || 0
          this.contas.caucoes[1] += parseInt(this.meta(this.caixas[i], 'valor recebido')) || 0
          this.contas.meias[1] += parseInt(this.meta(this.caixas[i], 'Anos 2')) || 0
          if (this.meta(this.caixas[i], 'PP')) {
            this.contas.meiasPP += parseInt(this.meta(this.caixas[i], 'Anos 2')) || 0
          }

          this.contas.outros[1] += parseFloat(this.meta(this.caixas[i], 'Anos 4')) || 0
          this.contas.outros1[1] += parseFloat(this.meta(this.caixas[i], 'Cacifo')) || 0

          this.contas.outros1[2] += this.meta(this.caixas[i], 'Criança') + ' / '

          this.contas.outros[3] += this.meta(this.caixas[i], 'Obvs') + ' / '

          this.caixa1.fecho = this.meta(this.caixas[i], 'manha') || this.caixa1.fecho
          this.caixa1.fecho1 = this.meta(this.caixas[i], 'manha') || this.caixa1.fecho1 || 0
          this.caixa1.nome = this.caixa1.nome || this.meta(this.caixas[i], 'tarde')
          this.caixa1.id = this.caixas[i].id
        }

        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    }
  }
}
</script>
