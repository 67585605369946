<template>
  <div class="cinzento1" style="height: 100vh;">
    <div class="row">
      <div class="col-lg-12" style="padding-bottom: 40px;">
        <a class="icon wb-power float-right m-10" href="javascript:void(0)" @click="logout()" >
          <span class="sr-only">Sair</span>
        </a>
        <img style="height:200px" :src="$store.state.user.email.indexOf('.smf@') > 0 ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" />

        <h1 style="font-size: 120px; margin-top:50px;float:right;">{{ horaAtual }}</h1>
      </div>
      <div class="col-lg-9">
        <table class="table balizas">
          <thead>
            <tr>
              <th style="font-size: 72px;">ENTRADAS LIVRES >> SAIR</th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <tr style="background:#ddd" v-for="(dt, ind) in res.filter(a => (a.calendar_id == 67 || a.calendar_id == 110) && meta(a, 'chamar') && !meta(a, 'saiu'))" :key="ind">
              <td>
                <h1 style="font-size: 72px;text-transform: uppercase;"><b style="">{{meta(dt, 'Crianças')}}</b></h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-lg-3">
        <table class="table balizas">
          <thead>
            <tr>
              <th style="font-size: 72px;text-transform: uppercase;">FESTAS A LANCHAR</th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <tr :class="'text-center ' + (meta(dt, 'Cor Pulseira') ? meta(dt, 'Cor Pulseira') : coresFN(dt.event_at.split(' ')[1]))" v-for="(dt, ind) in res.filter(a => (a.calendar_id == 6 || a.calendar_id == 107) && meta(a, 'Lanche') == 2 && meta(a, 'Lanche') !=3 )" :key="ind">
              <td>
                <h1 :style="'font-size: 72px;text-transform: uppercase;color:#' + ( meta(dt, 'Cor Pulseira') === 'Castanho' || meta(dt, 'Cor Pulseira') === 'Preto' ? 'fff;' : '111;' )">{{ meta(dt, 'Cor Pulseira') ? meta(dt, 'Cor Pulseira') : coresFN(dt.event_at.split(' ')[1]) }}</h1>
              </td>
            </tr>
          </tbody>
        </table>
        <table class="table balizas" style="margin-top: 50px;">
          <thead>
            <tr>
              <th style="font-size: 72px;">FESTAS A SAIR</th>
            </tr>
          </thead>
          <tbody style="color:#000;">
            <tr :class="'text-center ' + (meta(dt, 'Cor Pulseira') ? meta(dt, 'Cor Pulseira') : coresFN(dt.event_at.split(' ')[1]))" v-for="(dt, ind) in res.filter(a => (a.calendar_id == 6 || a.calendar_id == 107) && meta(a, 'chamar') && !meta(a, 'saiu')) " :key="ind">
              <td>
                <h1 style="font-size: 72px;text-transform: uppercase;">{{meta(dt, 'Cor Pulseira') ? meta(dt, 'Cor Pulseira') : coresFN(dt.event_at.split(' ')[1])}}</h1>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { searchEvent } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { commonFunctionsMixin } from '../filters/funcoes'

export default {
  name: 'pageParque',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      res: [],
      livres: [],
      horaAtual: new Date().toLocaleTimeString(),
      timer: ''
    }
  },
  mounted () {
    this.SET_LOAD(true)
    this.formUrl()
    this.timer = setInterval(() => {
      this.atualizarHora()
    }, 1000)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST'
    ]),
    atualizarHora() {
      this.horaAtual = new Date().toLocaleTimeString()
    },
    formUrl () {
      let cal = []
      switch (this.$store.state.user.email) {
        case 'parque.smf@balizaslandia.pt':
          cal = [6, 67]
          break
        case 'parque.oaz@balizaslandia.pt':
          cal = [107, 110]
          break
      }
      const dd = new Date()
      searchEvent(this.axios, cal, dd.getFullYear(), ('0' + (1 + dd.getMonth())).slice(-2), '', '', '', ('0' + dd.getDate()).slice(-2), '').then((res) => {
        window.setTimeout(() => {
          this.formUrl()
        }, 60000)
        this.res = res.data
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    }
  }
}
</script>
