<template>
  <div class="row pr cinzento1 m-0">
    <div class="cinzento" style="width:100%">
      <a class="icon wb-power float-right m-10" href="javascript:void(0)" @click="logout()" >
        <span class="sr-only">Sair</span>
      </a>
      <img :src="$store.state.user.email === 'saidas.smf@balizaslandia.pt' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:68px;" />
      <span style="color:#fff;font-size:20px;">{{parseInt(day)}} de {{$t('meses.' + parseInt(month))}} de {{year}}</span>

      <span class="float-right mr-10 texto-branco mt-5" style="font-size: 21px;" v-text="horas"></span>
    </div>
    <div class="cinzento1" style="width:100%">
      <div class="table-responsive cinzento1">
        <table id="tabelaFixa" class="table balizas lanche">
          <thead>
            <tr>
              <th style="top: 0px;width:50px;" class="meio azul text-center">Hora Saída</th>
              <th style="top: 0px;width:150px;" class="meio azul text-center">Aniversariante</th>
              <th style="top: 0px;width:60px;" class="meio azul text-center">Cor</th>
              <th style="top: 0px;width:100px;" class="meio azul text-center">Total Crianças</th>
              <th style="top: 0px;width:300px;" class="meio azul text-center">Brinde</th>
              <th style="top:0px;" class="meio azul text-center">Brinde dos Pais</th>
              <th style="top:0px;" class="meio azul text-center">Observações de Lesões</th>
            </tr>
            <tr v-for="(dt, index) in res" :key="index" :class="'mao ' + (meta(dt, 'Exterior') ? 'exterior' : '') + ((dt.event_at.split(' ')[1] == '14:00:00' && !dt.ocultar || dt.event_at.split(' ')[1] == '17:00:00' && !dt.ocultar || dt.event_at.split(' ')[1] == '11:30:00' && !dt.ocultar) ? ' border-verm ' : '') + (meta(dt, 'Lanche') == 3 ? 'cinzento1' : '')">

              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" :class="'meio hora text-danger text-center ' + (new Date() > addHours(dt.event_at.substring(0, 10) + ' ' + saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt), -5 ) ? 'blinking' : '')" style="font-weight:bold;font-size:15px;">{{saidaFN(meta(dt, 'Prolongamento festa (+1h)') && meta(dt, 'Prolongamento festa +30m') ? 3.5 : (meta(dt, 'Prolongamento festa (+1h)') ? 3 : (meta(dt, 'Prolongamento festa +30m') ? 2.5 : 2)), dt.event_at, dt)}}</td>

              <td :style="meta(dt, 'pagou') ? '' : 'color:#f00;'" class="meio">
                <label class="break" v-if="meta(dt, 'menu') == 'Centro Estudos'">{{dt.eventable.name}}</label>
                <label class="break" v-html="meta(dt, 'Filho 1')" v-if="meta(dt, 'Filho 1') && meta(dt, 'check1')"></label>
                <label class="break" v-html="meta(dt, 'Filho 2')" v-if="meta(dt, 'Filho 2') && meta(dt, 'check2')"></label>
                <label class="break" v-html="meta(dt, 'Filho 3')" v-if="meta(dt, 'Filho 3') && meta(dt, 'check3')"></label>
                <label class="break" v-html="meta(dt, 'Filho 4')" v-if="meta(dt, 'Filho 4') && meta(dt, 'check4')"></label>
              </td>

              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" @click="dt.id ? upData(dt): $router.push('/balizaslandiacriar' + (dt.id ? '/' + dt.id : '?d=' + dt.event_at))" style="color:#ddd" :class="'text-center hidden-sm-down cinzento1 ' + (dt.tipo === 'cal' ? '' : (meta(dt, 'Cor Pulseira') ? meta(dt, 'Cor Pulseira') : coresFN(dt.event_at.split(' ')[1])))"></td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-center"><b>{{meta(dt, 'Total Crianças')}}</b></td>

              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="extras-null meio">
                <label class="break" v-html="meta(dt, 'Brindes', 'Brindes1')"></label>
              </td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-left">{{meta(dt, 'brinde_pais')}}</td>
              <td :rowspan="linhasFN(dt, index)" v-if="!dt.ocultar" class="meio text-left">{{meta(dt, 'Observações Lesões')}}</td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
    <iframe id="talaoPrint" name="talaoPrint" style="visibility:hidden;position:absolute;top:-10000px;width:1px;height:1px;"></iframe>
  </div>
</template>

<script>
import { searchEvent } from '@/resources/fn'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import { commonFunctionsMixin } from '../filters/funcoes'

export default {
  name: 'pageSaidas',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      res: [],
      year: '',
      month: '',
      day: '',
      ano: new Date().getFullYear() + 1,
      scrollable: false,
      horas: ''
    }
  },
  mounted () {
    this.SET_LOAD(true)
    let a = new Date()
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    this.init()
    this.relogio()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE'
    ]),
    relogio () {
      let dd = new Date()
      this.horas = dd.getHours() + ':' + ('0' + dd.getMinutes()).slice(-2)
      _.delay(function (a) {
        a.relogio()
      }, 1000, this)
    },
    init () {
      this.formUrl('', 1)
    },
    formUrl () {
      this.SET_LOAD(true)
      let cal = []
      switch (this.$store.state.user.email) {
        case 'saidas.smf@balizaslandia.pt':
          cal = [6]
          break
        case 'saidas.oaz@balizaslandia.pt':
          cal = [107]
          break
      }
      searchEvent(this.axios, cal, this.year, this.month, '', '', '', this.day, '').then((res) => {
        this.res = res.data.filter(a => !this.meta(a, 'Bloqueado') && !a.birthday && new Date() <= this.addHours(a.event_at.substring(0, 10) + ' ' + this.saidaFN(this.meta(a, 'Prolongamento festa (+1h)') && this.meta(a, 'Prolongamento festa +30m') ? 3.5 : (this.meta(a, 'Prolongamento festa (+1h)') ? 3 : (this.meta(a, 'Prolongamento festa +30m') ? 2.5 : 2)), a.event_at, a))).sort((a, b) => new Date(a.event_at.replace(/-/g, '/')) - new Date(b.event_at.replace(/-/g, '/')))
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
      _.delay(function (a) {
        a.formUrl()
      }, 60000, this)
    },
    addHours(date, op) {
      let dd = new Date(date)
      dd.setMinutes(dd.getMinutes() + (op || 15))
      return dd
    },
    saidaFN (horas, dta, dt, quinze) {
      let dd = new Date(dta)
      if (horas === 2.5 || horas === 3.5) {
        dd.setMinutes(dd.getMinutes() + 30)
      }
      dd.setHours(dd.getHours() + parseInt(horas))
      if (this.meta(dt, 'menu').indexOf('Centro Estudos') === -1 && !quinze) {
        dd.setMinutes(dd.getMinutes() + 15)
      }
      return dd.getHours() + ':' + ('0' + dd.getMinutes()).slice(-2)
    }
  }
}
</script>
