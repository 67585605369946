<template>
  <div class="row pr cinzento m-0" style="min-height: 100vh;">
    <modal name="opcoesEventos" height="auto" :scrollable="scrollable" :adaptive="true" @re-open="scrollable = true" @before-close="scrollable = false" style="max-height: 100vh;overflow-y: auto;">
      <div class="panel m-0">
        <div class="panel-heading pr" style="background:#65A9E8;color:#fff;">
          <button @click="$modal.hide('opcoesEventos');" class="float-right btn btn-pure btn-md"><i class="icon wb-close"></i></button>
          <div class="clearfix"></div>
          <h3 class="panel-title text-uppercase texto-branco text-center">{{modalShow.title}}</h3>
        </div>
        <div class="panel-body pt-40 row">
          <h4 class="col-12">Adicionar Bilhete</h4>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Nome</span>
              <input type="text" class="form-control" v-model="bilhete.nome" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Email</span>
              <input type="text" class="form-control" v-model="bilhete.email" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Contacto</span>
              <input type="text" class="form-control" v-model="bilhete.telefone" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">crianças</span>
              <input type="text" class="form-control" v-model="bilhete.crianca" @keyup="totalBilhete()" />
            </div>
          </div>
          <div class="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Observações</span>
              <textarea class="form-control" v-model="bilhete.obs"></textarea>
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Total</span>
              <input type="text" class="form-control" v-model="bilhete.total" />
            </div>
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
            <div class="input-group">
              <span class="input-group-addon">Pagamento</span>
              <select class="form-control" v-model="bilhete.pagamento">
                <option value=""></option>
                <option value="Numerário">Numerário</option>
                <option value="Transferência">Transferência</option>
                <option value="Mbway">Mbway</option>
              </select>
            </div>
          </div>
          <div class="clearfix"></div>
          <a @click="atualizarBilhetes()" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar</a>
          <a @click="atualizarBilhetes(1)" class="btn btn-block btn-primary" href="javascript:void(0)">Guardar e Faturar</a>
          <a @click="$modal.hide('opcoesEventos');" class="btn btn btn-block btn-default" href="javascript:void(0)">Cancelar</a>
        </div>
      </div>
    </modal>

    <div class="text-center balizas-calendario cinzento">
      <a @click="SET_BALIZAS('POS');$router.push('/');" class="mao">
        <img :src="$store.state.balizaslandia === 'smf' ? $store.state.logoBalizaslandia1 : $store.state.logoBalizaslandia2" style="width:95%; max-width: 230px;" /></a>
      <div>
        <div class="p-10"><button class="btn btn-default" @click="onDayClick">Hoje</button></div>
        <v-calendar ref="calendar" :attributes="attributes" @dayclick="onDayClick"></v-calendar>
      </div>
    </div>
    <div class="balizas-tabela cinzento1">
        <router-link class="btn btn-primary mt-10 ml-20 pl-40 pr-40 cinzento" to="/balizaslandia"><b>MARCAÇÃO DE FESTAS</b></router-link>
        <router-link class="btn btn-primary mt-10 ml-20 pl-40 pr-40 cinzento" to="/balizaslandiaLivre"><b>ENTRADAS LIVRES</b></router-link>
        <h3 class="ml-20 mt-10">Eventos
          <button @click="$router.push('/balizaslandiacriarEvento')" class="btn btn-default" style="background:#fff;border: solid 1px #000; color:#000;">Inserir Evento</button>
        </h3>

        <div class="cinzento1">
          <table id="tabelaFixa" class="table balizas">
            <thead>
              <tr>
                <th style="top:0px;width:200px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Evento</th>
                <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Data</th>
                <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Início</th>
                <th style="top:0px;width:100px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Fim</th>
                <th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Entrada (€)</th>
                <th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Vendidos</th>
                <!--<th style="top:0px;width:60px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Total(€)</th>-->
                 <th style="top:0px;width:200px;" :class="{'meio text-center': 1, azul: $store.state.balizaslandia === 'smf', amarelo: $store.state.balizaslandia === 'oaz'}">Observações</th>
                 <th style="top:0px;width:20px;" class="cinzento"></th>
              </tr>
            </thead>
            <tbody style="color:#000;">
              <tr v-for="(dt, index) in res" :key="index" :class="{'mao': 1, 'cinzento1': meta(dt, 'saiu') }" @click="$router.push('/balizaslandiacriarEvento/' + dt.id)">
                <td class="meio">{{dt.title}}</td>
                <td class="meio">{{dt.event_at.split(' ')[0]}}</td>
                <td class="meio">{{dt.event_at.substring(11, 16)}}</td>
                <td class="meio">{{meta(dt, 'tarde')}}</td>
                <td class="meio">{{dt.price}}</td>
                <td class="meio">{{totalBilhetes(dt)}}</td>
                <!--<td class="meio">{{JSON.parse(meta(dt, 'manha') || []).filter(a => a.nome).length * dt.price}} / {{dt.price * meta(dt, 'Sitio')}}</td>-->
                <td class="meio">{{dt.observation}}</td>
                <td><a @click.stop="$modal.show('opcoesEventos'); modalShow = dt; bilhete= { nome: '', email: '', telefone: '', pagamento: '', total: '', crianca: '', obs: '' }" class="icon wb-plus hidden-sm-down mao"></a></td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>
import { searchEvent } from '@/resources/fn'
import { mapMutations } from 'vuex'
import { commonFunctionsMixin } from '../filters/funcoes'

export default {
  name: 'pageBalizadslandiaEventos',
  mixins: [commonFunctionsMixin],
  data () {
    return {
      res: [],
      year: '',
      month: '',
      day: '',
      attributes: [{
        dates: new Date(),
        key: 'today',
        highlight: true
      }],
      ano: new Date().getFullYear() + 1,
      anos: [],
      scrollable: false,
      holidays: [],
      dataMin: '2017-01-01',
      modalShow: {},
      bilhete: {}
    }
  },
  mounted () {
    this.SET_TITLE('')
    this.SET_LOAD(true)
    let a = new Date()
    let xxx = decodeURIComponent(window.location.search).substring(3)
    if (xxx) {
      a = new Date(xxx.replace(/-/g, '/'))
      this.attributes[0].dates = a
      this.$refs.calendar.move(a)
    }
    this.month = ('0' + (1 + a.getMonth())).slice(-2)
    this.year = a.getFullYear()
    this.day = ('0' + a.getDate()).slice(-2)
    a = 2017
    while (a <= this.year + 1) {
      this.anos.push(a++)
    }
    this.init()
  },
  methods: {
    ...mapMutations([
      'SET_LOAD',
      'SET_TOAST',
      'SET_TITLE',
      'SET_BALIZAS'
    ]),
    totalBilhete () {
      let t = 0
      this.bilhete.forEach(a => {
        t += a.crianca ? a.crianca.split(',').length : 0
      })
      console.log(t)
      this.bilhete.total = t
    },
    totalBilhetes (dt) {
      let  t = 0
      JSON.parse(this.meta(dt, 'manha') || []).forEach(a => {
        if (a.crianca) {
          t += a.crianca.split(',').length
        }
      })
      return t
    },
    l (a) {
      return this.$t('home.' + a)
    },
    init () {
      this.formUrl('', 1)
      this.feriadosFN(this.year)
    },
    formUrl () {
      this.SET_LOAD(true)
      let cal = []
      switch (this.$store.state.balizaslandia) {
        case 'smf':
          cal = [129]
          break
        case 'oaz':
          cal = [130]
          break
      }
      searchEvent(this.axios, cal, this.year, '', '', '', '', '', '').then((res) => {
        this.res = res.data
        
        this.SET_LOAD()
      }, (er) => {
        this.SET_TOAST({ msg: er.data, type: 'danger' })
      })
    },
    onDayClick (dia) {
      if (!dia.id) {
        let a = new Date()
        this.month = ('0' + (1 + a.getMonth())).slice(-2)
        this.year = a.getFullYear()
        this.day = ('0' + a.getDate()).slice(-2)
        dia = { id: this.year + '-' + this.month + '-' + this.day }
      }
      this.attributes[0].dates = new Date(dia.id.replace(/-/g, '/'))
      this.$refs.calendar.move(dia.id)
      this.month = dia.id.split('-')[1]
      this.year = dia.id.split('-')[0]
      this.day = dia.id.split('-')[2]
      this.feriadosFN(this.year)
      // this.formUrl()
    },
    horaFN () {
      let a = new Date()
      // a.setMinutes(a.getMinutes() + 5)
      return ' ' + (a.getHours() < 10 ? '0' + a.getHours() : a.getHours()) + ':' + (a.getMinutes() < 10 ? '0' + a.getMinutes() : a.getMinutes()) + ':00'
    },
    saidaFN (horas) {
      let a = new Date()
      return a > new Date(a.getFullYear() + '-' + (1 + a.getMonth()) + '-' + a.getDate() + ' ' + horas)
    },
    atualizarBilhetes (op) {
      console.log(op)
    }
  }
}
</script>
